@import 'style/scss/app';

@mixin display {
  display: flex;
  align-items: center;
}

@mixin link__menu__header($width, $font-size) {
  width: $width;
  a {
    font-size: $font-size;
    transition: 0.3s;
    color: $color-text;
    padding: 8px 30px;
    border-radius: 6px;
    width: 23%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin checkbox {
  &[type='checkbox'] {
    accent-color: #283a97;
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #c8cce1;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
  }

  &[type='checkbox']:checked {
    background: url('../img/checkbox.svg') no-repeat #283a97 center center;
  }

  &[type='radio'] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #c8cce1;
    border-radius: 50%;
    position: relative;
  }

  &[type='radio']::before {
    content: '';
    position: absolute;
    top: 50%;
    border: 2px solid #c8cce1;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--checkbox-bg);
    opacity: 0;
  }

  &[type='radio']:checked::before {
    accent-color: #283a97;
    opacity: 1;
    background: url('../img/checkbox.svg') no-repeat #283a97 center center;
  }
}

@mixin theme__changing__button {
  @include display;
  width: 100%;
  height: 100%;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 8px;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;
}

@mixin scroll {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    background: var(--scroll-bar-trak-bg);
    border-radius: 6px;
    margin: 20px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scroll-bar-trak-thumb);
    border-radius: 6px;
    border: 3px solid var(--scroll-bar-trak-bg);
    cursor: pointer;
  }
}

@mixin scrollTable {
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    width: 6px;
    background-color: #fff;
    border-radius: 6px;
    margin: 20px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8b8fa4;
    border-radius: 6px;
    border: 3px solid var(--scroll-bar-trak-bg);
    cursor: pointer;
  }
}
