@import '../../style/scss/app.scss';

.achievement {
  background-color: $white-text;
  height: 120px;
  width: 350px;
  border-radius: 6px;
  display: flex;
  padding: 15px;
  gap: 25px;
  box-shadow: 0 0 10px 0 #283A9733;

  &__photo {
    max-width: 60px;
    max-height: 60px;
    margin-bottom: 20px;
  }

  .achievement__text {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__title {
    color: var(--color-text);
    font-size: 20px;
    font-weight: 600;
  }

  &__description {
    color: var(--color-text);
    font-size: 15px;
  }

  &__admin {
    display: flex;
    justify-content: end;
  }
}


.edit__btn {
  width: 24px;
  height: 24px;
  background-image: var(--admin-panel-edit-button-img);
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.delete__btn {
  width: 24px;
  height: 24px;
  background-image: url('../../style/img/delete-icon.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
