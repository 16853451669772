@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins.scss';

.course__lesson {
  width: 100%;
  margin: 40px 0;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px 10px 10px 0;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #fff;
    border-radius: 20px;
    cursor: pointer;

    &__title {
      font-weight: 600;
      font-size: 17px;
    }

    .course__lesson__states__wrapper {
      @include display;
      gap: 20px;

      .lesson__arrow {
        height: 15px;
        width: 15px;
        rotate: -180deg;
        transition: 0.2s;

      }

      .lesson__arrow.open {
        rotate: 0deg;
        transition: 0.2s;
      }

      .course__lesson__done__status {
        width: 35px;
        height: 35px;
        border-radius: 100%;
      }
    }
  }

  &__taskstrings {
    overflow: hidden;
    max-height: 0;
    transition: .3s;
  }

  .task__string__block {
    display: flex;
  }
}