@import '../../../style/scss/app.scss';
.close__form__button__wrapper {
    top: 0;
    right: 0;
    position: absolute;
    width: 40px;
    height: 40px;

    .close__form__button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0 5px 0 15px;
        background-color: $color-error;
        background-image: url('../../../style/img/close-cross-icon.svg');
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
    }
}