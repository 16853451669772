@import '../../style/scss/app.scss';
@import '../../style/scss/mixins.scss';

.course {
  display: flex;
  flex-direction: column;
  max-height: 260px;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.2);
  cursor: pointer;

  &__image {
    background-color: rgb(0, 0, 0, 0.4);
    border-radius: 20px 20px 0 0;
    width: 100%;
    max-height: 260px;
    min-height: 215px;
    z-index: 0;
    overflow: hidden;
  }

  &__info {
    @include display;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    height: 22%;
    padding: 17px 22px;
    z-index: 1;
    position: relative;
    background-color: white;
    border-radius: 0 0 20px 20px;

    &_admin {
      @include display;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;
      padding: 17px 22px;
      z-index: 1;
      position: relative;
      background-color: white;
      border-radius: 0 0 20px 20px;
    }
  }
}

.statistic {
  font-weight: 700;
  font-size: 12px;
  color: #283a97;
  cursor: pointer;
}

.edit {
  @include display;
  gap: 4px;
  &_text {
    font-size: 14px;
    font-weight: 700;
    color: #283a97;
  }
}
