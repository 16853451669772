@import '../../../style/scss/app.scss';
@import '../../../style/scss/mixins.scss';

p, h1, h2, h3, h4 {
  color: $color-text;
}

.python {
  margin-bottom: 50px;
  @include display;
  justify-content: center;

  .python__content {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .note__section {

    h3 {
      font-size: 26px;
    }

    .note__list {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 1350px) {
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }

  .backend__section {
    display: flex;
    flex-direction: column;
    gap: 25px;
    background-color: var(--must-background-color);
    padding: 50px;
    border-radius: 20px;
    box-shadow: 3px 3px 15px var(--color-shadow);
    @media (max-width: 950px) {
      padding: 20px;
    }
  }

}
