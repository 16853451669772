@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.cyrcle {
  @include display;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  background-color: #283a97;
  cursor: pointer;

  &_selecteed {
    @include display;
    justify-content: center;
    background-color: #fafeff;
    border: 1px solid #283a97;
    color: #283a97;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  &_white {
    @include display;
    justify-content: center;
    color: #fff;
    width: 30px;
    height: 30px;
    border: 1px solid #283a97;
    border-radius: 50%;
    cursor: pointer;
  }
  &_red {
    @include display;
    justify-content: center;
    background-color: #fff9f9;
    border: 1px solid #ff3d3d;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
  }

  &_counter {
    @include display;
    gap: 6px;
  }
}
