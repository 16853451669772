@import "style/scss/mixins";

.label {
  font-size: 17px;
  @include display;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  border: 1px dashed #cecece;
  padding: 8px 10px;
  border-radius: 8px;
  text-align: left;
  user-select: none;
  cursor: pointer;
  background-color: #F8F8FF;
  margin: 20px ;
}

input[type=file] {
  display: none;
}

.text {
  text-align: left;
  margin-top: 20px;

  span {
    color: red;
  }
}

.upload {
  width: 100px;
  height: 60px;
  &__title {
    font-weight: 700;
  }
  &__text {
    color: #7C7C7C;
    font-size: 12px;
  }
}
