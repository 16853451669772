@import "style/scss/mixins";

.answer {

  width: 900px;
  height: calc(80vh - 100px);
  margin: 150px auto;
  background-color: var(--tabel-users-bg);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);
  overflow-y: scroll;
  @include scroll;

  &__bg {
    @include display;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #283A9733;
    width: 100%;
    height: 100%;
  }
  &__close {
    text-align: right;
    cursor: pointer;
    border-bottom: 1px solid var(--color-text);
    margin-bottom: 20px;
  }
}