@import '../../../../../../style/scss/app.scss';
@import '../../../../../../style/scss/app.scss';

.cart__navigate {
  height: 100%;
  cursor: pointer;
  a, p, h1, h2, h3 {
    height: 100%;

    transition: 0.1s;
  }
}
.active {
  border-bottom: 3px solid white;
  transition: 0.1s;
  padding-top: 2px;

  @media (max-width: 450px) {
    color: #283A97;
  }
}

.title {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    color: #000;
    font-weight: 700;
    font-size: 18px;
    justify-content: flex-start;
    padding: 0 20px;
    margin-top: 10px;
  }
}