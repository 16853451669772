@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.compiler {
  @include display;
  justify-content: center;

  @media (max-width: 450px) {
    margin: 50px 0;
  }

  &__btn {
    width: 150px;
    padding: 13px 30px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    background-color: #06ab06;
    margin-right: 20px;
    transition: .3s;


    @media (max-width: 450px) {
      width: 100%;
    }

    &_next {
      padding: 13px 30px;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      color: #06ab06;
      background-color: $white-text;
      border: 1px solid #06ab06;
      margin-right: 20px;
      transition: .3s;
      width: 150px;

      @media (max-width: 450px) {
        margin-top: 10px;
        width: 100%;
      }
    }

    &_error {
      padding: 13px 30px;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      background-color: #FFF9F9;
      margin-right: 20px;
      border: 1px solid #FF3D3D;
      color: #FF3D3D;
      transition: .3s;
      width: unset;
      @media (max-width: 450px) {
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  &__link {
    width: 150px;
    padding: 13px 30px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    color: $white-text;
    background-color: $color-blue;

    &:hover {
      background-color: var(--admin-button-hover-background);
    }
  }


  .compiler__wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    gap: 10px;
    background-color: $white-text;
    box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);
    padding: 24px;


    @media (max-width: 880px) {
      width: 100%;
      margin: 20px 0 0 0;
      padding: 12px;
    }

    .compiler__console {
      padding-top: 20px;
      width: 100%;
      height: 25%;
      @include display;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 880px) {
        height: 35%;
      }

      .compiler__console__title__wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 25%;


        .compiler__console__title {
          background-color: var(--compiler-bg);
          color: var(--color-text);
          padding: 12px;
          max-width: 150px;
          font-weight: 600;
          font-size: 16px;
          width: 100%;
          clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 10%);
          border-radius: 8px 0 0 0;
          @include display;
        }


        @media (max-width: 500px) {
          .compiler__console__title {
            font-size: 14px;
          }
          .compiler__console__buttons__wrapper {

            Button {
              padding: 10px;
              @media (max-width: 400px) {
                padding: 5px;
              }
            }
          }
        }
      }

      hr {
        width: 100%;
        border: 1px solid #bfbfbf;
      }

      .compiler__console__text {
        height: 75%;
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
        padding: 12px;
        font-weight: 500;
        color: var(--color-text);
        background-color: var(--compiler-bg);

        .compiler__console__result {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .compiler__console__result.success {
          color: $color-task-done;
        }

        .compiler__console__result.failed {
          color: $color-error;
        }
      }

      .compiler__console__text::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }
}

@media (max-width: 880px) {
  .compiler__content__wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.compiler__console__buttons__wrapper {
  gap: 10px;
  padding-bottom: 8px;
  @include display;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.compiler__content__wrapper {
  width: 65%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 40px;
  background-color: $white-text;
  padding: 40px;
  border-radius: 24px;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);


  @media (max-width: 834px) {
    margin-top: 60px;
  }
  @media (max-width: 450px) {
    margin-top: 0;
    padding: 16px;
    width: 90%;
  }


}

.compiler__task__theory {
  width: 100%;
  display: flex;
  max-height: 500px;
  flex-direction: column;

  @media (max-width: 880px) {
    height: 50vh;
    width: 100%;
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    color: var(--color-text);
    padding-bottom: 15px;
    margin: 20px 0;
  }

  &__text {
    word-break: break-all;
    padding: 15px 10px 6px 6px;
    line-height: 22px;
    overflow-y: scroll;
    width: 100%;
    word-wrap: break-word;

    @include scroll;
    margin-bottom: 20px;

    @media (max-width: 450px) {
      overflow-y: scroll;
      @include scroll;
    }

    span {
      filter: var(--invert-text);
      font-size: 20px;
    }

    a {
      filter: var(--invert-text);
      font-size: 20px;
      text-decoration: underline;
    }
  }
}

.compiler__theory {
  &__name {
    font-size: 20px;
  }

  &__subtitle {
    margin-top: 15px;
  }
}
.btn__block {
  @include display;
  gap: 10px;
  @media (max-width: 450px) {
    display: unset;
  }
}