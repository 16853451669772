@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.register {
    display: grid;
    background: rgb(0, 0, 0, 0.5);
    grid-template-columns: 2fr 0.914fr;
    height: 100%;
    width: 100%;
    position: absolute;
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
    
}
