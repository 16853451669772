@import 'style/scss/app';
@import 'style/scss/mixins.scss';

.task {
  min-width: 32%;
  max-width: 32%;
  max-height: 170px;
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;

  @media (max-width: 450px) {
    max-width: unset;
    width: 94%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__result {
    color: #fff;
    margin-top: 6px;
  }

  &__text {
    &__notdone, &__done, &__close {
      color: #fff;
    }

    &_block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 450px) {
        gap: 10px;
      }
    }
  }


  &__title {
    font-weight: 600;
    font-size: 20px;
    align-self: flex-start;

    @media (max-width: 450px) {
      font-size: 18px;
      font-weight: 700;

    }

    &_block {
      display: flex;
      align-items: flex-start;
    }
  }

  &__complexity {
    font-weight: 600;
    font-size: 15px;
    align-self: flex-start;
    margin-left: 20px;

  }


  &__container {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .task__exp {
      font-weight: 500;
      font-size: 16px;
    }

    .task__isdone {
      display: flex;
    }
  }
}

.status {
  width: 18px;
  height: 18px;
  margin: 3px 5px 0 0;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 450px) {
    align-items: flex-start;
    margin: 10px 0 0 20px;
  }
}

.complete {
  background-color: #EDF7ED;
}

.blocked {
  background-color: #FDEDED;

}

.progress {
  background-color: #E5F6FD;
}

.easy {
  color: $color-easy-complexity;
}

.medium {
  color: #014361;
}

.hard {
  color: #5F2120;
}

.done__color {
  color: $color-task-done;
}

.notdone__color {
  color: $color-task-notdone;
}

.close__color {
  color: $color-task-close;
}

.done {
  color: #1E4620;
}

.notdone {
  background-color: $color-task-notdone;
}

.close {
  background-color: $color-task-close;
}
