@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins.scss';

.course__description__wrapper {
  @include display;
  justify-content: center;
  padding: 120px 0 0 0;

  @media (max-width: 1440px) {
    padding: 100px 0 0;
  }
  @media (max-width: 900px) {
    padding: 50px 0 0;
  }

  .course__description__content__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border-radius: 20px 20px 0 0;
    box-shadow: 0px -6px 8px 0px rgba(34, 60, 80, 0.2);
    background-color: $white-text;

    @media (max-width: 900px) {
      flex-direction: column-reverse;
      align-items: center;
    }


    .course__description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;
      width: 100%;
      padding: 30px 0 0 30px;


      @media (max-width: 900px) {
        margin-top: 50px;
        gap: 35px;
      }

      @media (max-width: 450px) {
        margin-top: 0;
        gap: 0;
      }

      &__title {
        font-size: 35px;
        color: var(--color-text);
        @media (max-width: 1000px) {
          font-size: 32px;
        }
      }

      .course__description__text {
        width: 86%;
        color: var(--color-text);
        @media (max-width: 1440px) {
          width: 90%;
        }
        @media (max-width: 1000px) {
          width: 92%;
        }
      }

      .btn__block {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;

        .btn__stat {
          width: 100%;
          padding: 15px 0;
        }
      }

      .btn__next__description {
        width: 100%;
        display: flex;
        gap: 15px;
        @media (max-width: 500px) {
          flex-direction: column;
        }

        Button {
          width: 50%;
          padding: 15px 0;
          font-size: 15px;
          font-weight: 500;
          @media (max-width: 900px) {
            font-size: 14px;
            padding: 15px 0;
            width: 100%;
          }
        }
      }
    }

    .course__preview__image {
      width: 55%;
      height: 100%;

    }
  }
}

.lessons {
  width: 120px;
  font-weight: 600;
  font-size: 20px;
}