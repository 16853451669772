.header {
  display: flex;
  background-color: #283A97;
  width: 100%;
  z-index: 2;
  height: 65px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;

  @media (max-width: 450px) {
    border-radius: 0;
  }

  .header__wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  }
}