.user {

  &__name {
    color: #283A97;
  }

  &__info_block {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__avatar {
    width: 60px;
    height: 60px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 186px;

    @media (max-width: 1440px) {
      width: 160px;
      height: 160px;
      min-height: 160px;
      min-width: 160px;
    }
  }

}