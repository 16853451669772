.modal__navigate {
  position: absolute;
  top: 65px;
  background-color: white;
  padding: 20px 0;
  width: 145%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 15px;
  transition: 0.30s;
  animation: openModal 0.5s;
  animation-fill-mode: forwards;
  z-index: 3;


}

.close {
  position: absolute;
  top: 65px;
  background-color: white;
  padding: 20px 0;
  width: 145%;
  border-radius: 15px;
  transition: 0.30s;
  animation: hidenModal 0.2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  z-index: 0;

  &_routes {
    z-index: 0;
    position: fixed;
    left: -1000px;
  }
}

.close__modal__btn {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(40, 58, 151, 0.2);
  z-index: 2;
}

@keyframes hidenModal {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes openModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.routes {
  @media (min-width: 450px) {
    display: none;
  }
}