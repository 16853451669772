@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/app.scss';

.user__header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.modal__user {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  cursor: pointer;
}

.notifications {
  cursor: pointer;
  margin-right: 6px;
  padding: 3px;
  border-radius: 50%;

  &:hover {
    background-color: rgba(124, 124, 124, 0.22);
    transition: .4s;
    border-radius: 50%;
  }
}