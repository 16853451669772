@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';


.left__panel {
  background-color: var(--header-footer-background-color);
  width: 25%;
  height: 100vh;
  .panel__search {
    width: 93.5%;

    @include display;
    justify-content: space-between;
    border-radius: 8px;
    background-color: var(--search-background);
    margin: 5px 10px;
    &__input {
      width: 90%;
      border: none;
      border-radius: 8px;
      outline: none;
      padding: 10px;
      font-size: 14px;

      background-color: transparent;
      color: $white-text;
    }
    &__input::placeholder {
      font-size: 14px;

    }

    &__button {
      height: 100%;
      padding: 25px;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      background-color: transparent;
      cursor: pointer;
      background-image: url('../../../../style/img/search-icon-dark.svg');
    }
  }

  .scroll__visible__block {
    width: 100%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
  }
}
