@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';


.title__documentation {
  border: 1.5px solid $color-text;
  border-radius: 35px;
  padding: 10px 70px;
  @include display;
  gap: 10%;
  box-shadow: 0 0 20px rgb(0, 0, 0, 0.2);
  @media (max-width: 950px) {
    padding: 20px 50px;
    gap: 5%;
  }
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 25px 25px;
  }

  .description {
    font-size: 22px;
  }

  &_btn {
    font-size: 20px;
  }

  p {
    @media (max-width: 950px) {
      font-size: 20px;
    }
  }

  h2 {
    font-size: 40px;
    @media (max-width: 950px) {
      font-size: 26px;
    }
  }

  h3 {
    font-size: 25px;
    @media (max-width: 950px) {
      font-size: 22px;
    }
  }

  img {
    max-width: 30%;
    @media (max-width: 950px) {
      width: 25%;
    }
    @media (max-width: 650px) {
      width: 60%;
      max-width: none;
    }
  }

  button {
    width: 20%;
    padding: 10px 15px;
    color: $white-text;
    font-weight: 600;
    background: $color-blue;
    border: none;
    border-radius: 7px;
    @media (max-width: 1400px) {
      width: 30%;
    }
    @media (max-width: 950px) {
      width: 35%;
    }
    @media (max-width: 650px) {
      width: 100%;
    }

  }

  .information__course__block {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 80%;
    @media (max-width: 1400px) {
      width: 95%;
    }
    @media (max-width: 950px) {
      width: 100%;
    }
  }

  .description__course {
    display: flex;
    flex-direction: column;
    gap: 15px;

  }
}

.name__course {
  font-size: 40px;
}