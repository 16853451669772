@import 'style/scss/mixins';

.table {
  width: 40%;
  height: 80vh;
  margin: 50px auto;
  border-radius: 20px;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);
  background-color: var(--tabel-users-bg);
  @include scrollTable;
  overflow-y: scroll;

  @media (max-width: 450px) {
    width: 96%;
  }

  &__close {
    text-align: right;
    margin: 0 10px 15px 0;
    cursor: pointer;
    color: var(--color-text);
  }

  &__bg {
    @include display;
    justify-content: center;
    padding: 30px;
    background-color: #283a9733;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__body {
    height: calc(80vh - 150px);
    overflow-y: scroll;
    @include scroll;
  }
}

.textarea {
  border: 1px solid #d4daec;
  padding: 12px;
  width: 91%;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  line-height: 20px;
  resize: none;
  min-height: 150px;
  @include scrollTable; 
  max-height: 2000px;
  margin: 20px;
}

.lessons {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  gap: 10px;
}

.name {
  font-size: 16px;
}

.cyrcle {
  &_counter {
    @include display;
    gap: 6px;
  }
}

.title {
  border: 1px solid #d4daec;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  line-height: 20px;
}
