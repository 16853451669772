@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.started__course {
  min-height: 170px;
  width: 336px;
  height: 170px;
  padding: 20px;
  border-radius: 15px;
  @include display;
  justify-content: center;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);
  background: var(--started-course-background);
  margin-top: 20px;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    width: 550px;
    gap: 10px;
  }

  @media (max-width: 1650px) {
    padding: 16px;
    height: 235px;
    width: calc(100% / 2 - 15px);
  }
  @media (max-width: 1440px) {
    padding: 15px;
    height: 200px;
    width: calc(100% / 2 - 15px);
  }
  @media (max-width: 1280px) {
    padding: 14px;
    height: 180px;
    width: calc(100% / 2 - 10px);
  }
  @media (max-width: 730px) {
    padding: 12px;
    height: 170px;
    width: calc(100% / 2 - 5px);
  }
  @media (max-width: 700px) {
    padding: 12px;
    height: 170px;
    width: 85%;

  }
  @media (max-width: 450px) {
    padding: 12px;
    height: 170px;
    width: 100%;
  }


  h2 {
    font-weight: 700;
    color: $color-text;
    @media (max-width: 1440px) {
      font-size: 18px;
    }
  }

  .started__course__progress__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      font-size: 14px;
      color: $color-text;
      font-weight: 600;
      @media (max-width: 1440px) {
        font-size: 12px;
      }
    }

    .started__course__progress {
      width: 100%;
      height: 10px;
      border-radius: 7px;
      background-color: $color-gray;

      .started__course__progress__line {
        height: 100%;
        width: 50%;
        border-radius: 7px;
        background-color: $color-blue;
      }
    }
  }

  .continue__course__button {
    padding: 12px;
    cursor: pointer;
    background-color: $color-blue;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    max-width: 220px;
    transition: .3s;
    color: #fff;

    &__completed {
      background-color: green;
      padding: 12px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      border: none;
      border-radius: 10px;
      max-width: 220px;
      transition: .3s;
      color: #fff;
    }

    @media (max-width: 1650px) {
      padding: 10px;
    }
    @media (max-width: 1280px) {
      font-size: 14px;
      max-width: 160px;
    }
  }

  .continue__course__button:hover {
    background-color: $color-light-blue-hover;
  }

}

.title__block {
  @include display;
}

.pic {
  width: 35px;
  height: 35px;
  pointer-events: none;
  margin-right: 10px;
}

.count {
  color: #283A97;
  font-weight: 600;

  &_block {
    @include display;
    justify-content: space-between;
  }
}