@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins.scss';

.course__lessons__section {
  display: flex;
  justify-content: center;
  background-color: $white-text;
  margin: 0 auto;
  border-radius: 0 0 20px 20px;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);

}

.lists {
  width: 100%;
  padding: 0 30px;

  @media (max-width: 450px) {
    padding: 0 10px;
  }
}
  