@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.compiler {
  @include display;
  justify-content: center;

  &__btn {
    width: 150px;
    padding: 13px 30px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    color: $white-text;
    background-color: $color-blue;
    text-align: center;
    margin-top: 20px;
    text-decoration: none;

    @media (max-width: 450px) {
      display: none;
    }
  }
}

.compiler__wrapper {
  height: 80vh;
  width: 60%;
  margin-left: 20px;
  overflow: hidden;
  border-radius: 10px;

  @media (max-width: 880px) {
    width: 100%;
    height: 60vh;
    margin: 50px 0 0 0;
  }

  @media (max-width: 500px) {
    .compiler__console__title {
      font-size: 14px;
    }
  }
}

.buttons__wrapper {
  @include display;
  gap: 6px;
}


@media (max-width: 880px) {
  .compiler__content__wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.check {
  @include display;
  justify-content: center;
  width: 110px;
  padding: 8px 12px;
}


.compiler__content__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 90px;

  @media (max-width: 834px) {
    margin-top: 60px;
  }
  @media (max-width: 450px) {
    margin: 50px 0 ;
  }
}

.compiler__task__theory {
  width: 40%;
  display: flex;
  flex-direction: column;

  @media (max-width: 880px) {
    height: 50vh;
    width: 100%;
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    color: var(--color-text);
    border-bottom: 2px solid var(--compiler-hr);
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  &__text {
    padding: 15px 25px 6px 6px;
    line-height: 22px;
    height: calc(80vh - 80px);
    overflow-y: scroll;
    word-wrap: break-word;
    @include scroll;

    @media (max-width: 450px) {
      min-height:200px;
      height: 80vh ;

    }

    span {
      filter: var(--invert-text);
      font-size: 20px;
    }

    a {
      filter: var(--invert-text);
      font-size: 20px;
      text-decoration: underline;
    }
  }
}

.compiler__user__code {
  width: 100%;
  height: 75%;
  border-radius: 8px;
  background-color: var(--compiler-bg);
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 0;
  @media (max-width: 880px) {
    height: 65%;
  }
}

.compiler__console {
  padding-top: 20px;
  width: 100%;
  height: 25%;
  @include display;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 880px) {
    height: 35%;
  }
}

.compiler__console__title__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 25%;
}

.compiler__console__title {
  background-color: var(--compiler-bg);
  color: var(--color-text);
  padding: 12px;
  max-width: 150px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%, 0 10%);
  border-radius: 8px 0 0 0;
  @include display;
}

.compiler__console__text {
  height: 75%;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  padding: 12px;
  font-weight: 500;
  color: var(--color-text);
  background-color: var(--compiler-bg);

  .compiler__console__result {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .compiler__console__result.success {
    color: $color-task-done;
  }

  .compiler__console__result.failed {
    color: $color-error;
  }
}

.compiler__console__text::-webkit-scrollbar {
  width: 0;
  height: 0;
}