@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.admin__task__string {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 25px;
    
    .new__taskstring__toolbar {
        padding-top: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 15px;

        .new__taskstring__tool__button {
            width: 50px;
            height: 30px;
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: center;
            border: none;
            background-color: var(--admin-panel-add-block-inputs-background);
        }
        .add__new__task__on__taskstring {
            background-size: 12px 12px; 
            background-image: var(--admin-panel-add-plus-icon);  
        }
        .delete__admin__task__string {
            background-image: url('../../img/delete-icon.svg');
            background-size: 20px 20px;
        }
    }
    .admin__taskstring__tasks__wrapper {
        width: 100%;
        display: flex;
        gap: 25px;
        overflow: hidden;
        overflow-x: scroll;
    }
    .admin__taskstring__tasks__wrapper::-webkit-scrollbar {
        width: 100%;
    }
    .admin__taskstring__tasks__wrapper::-webkit-scrollbar-thumb {
        border-radius: 25px;
        height: 10px;
    }
}
