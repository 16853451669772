@import '../../../../style/scss/app.scss';
.sides__cart {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    color: $color-text;
    font-size: 16px;
  }

  h4 {
    font-size: 22px;

  }

}
