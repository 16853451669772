@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.add__course__block {

  &__title {
    color: var(--color-text);
  }

  .add__course__form {
    margin-top: 15px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: var(--admin-panel-add-block-background);

    @media (max-width: 600px) {
      flex-direction: column;

    }

    .add__course__inputs__wrapper {
      width: 45%;
      padding: 25px 0 0 50px;
      display: flex;
      flex-direction: column;
      gap: 25px;

      @media (max-width: 850px) {
        gap: 15px;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

    }

    .add__course__body {
      width: 55%;
      padding: 25px 50px 0 0;

      @media (max-width: 600px) {
        padding: 0;
        width: 77%;
        margin-left: 10%;
        justify-content: center;
      }

      .lessons__title {
        margin-bottom: 10px;
        color: var(--color-text);
        font-weight: 500;
      }


      .add__course__lessons__wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 25px;

        @media (max-width: 850px) {
          gap: 15px;
        }

        .add__lesson__button {
          width: 100%;
          height: 35px;
          @include display;
          padding-left: 15px;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 5px;
          background-color: var(--admin-panel-add-block-inputs-background);

          &__icon {
            width: 20px;
            height: 20px;
          }
        }

        .new__lesson {
          width: 100%;
          height: 35px;
          @include display;
          justify-content: space-between;
          padding-left: 15px;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 5px;
          background-color: var(--admin-panel-add-block-inputs-background);

          img {
            width: 25px;
            height: 15px;
            margin-right: 15px;
          }

          &__title__input {
            width: 40%;
            height: 100%;
            outline: none;
            border: none;
            background-color: transparent;
            padding-left: 10px;
            color: var(--color-text);

            @media (max-width: 600px) {
              width: 100%;
            }
          }

          &__confirm__button {
            width: 50px;
            height: 25px;
            cursor: pointer;
            margin-right: 15px;
            background-image: var(--admin-panel-confirm-image);
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-color: transparent;
            border-radius: 5px;
            border: var(--admin-panel-confirm-border);
          }
        }

        .new__lesson:hover,
        .add__lesson__button:hover {
          transform: scale(1.005);
        }
      }
    }

    .add__course__buttons {
      padding-left: 50px;
      width: 100%;
      height: 80px;
      @include display;
      gap: 50px;
    }
  }
}