@import "style/scss/mixins";

.choice {
  &__text {
    font-size: 20px;
    margin-left: 4px;
    cursor: pointer;

    &_reject {
      color: #981515;
      font-size: 25px;

    }

    &_seccess {
      color: #2DBD65;
      font-size: 25px;

    }

    &_wasCompleted {
      color: #888888;
      font-size: 25px;
    }
  }
}

.block {
  @include display;
  gap: 10px;
  margin: 10px 0;

  @media (max-width: 450px) {
    width: 100%;
  }

  &__border {
    &_error {
      border: 2px solid #981515;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      @media (max-width: 450px) {
        width: 28px;
        border: 1px solid #981515;
      }
    }

    &_secces {
      border: 2px solid #2DBD65;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      @media (max-width: 450px) {
        width: 44px;
        border: 1px solid #2DBD65;

      }
    }
  }
}