.faq_container{
  margin: 50px auto;
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);
  width: 65%;


  @media (max-width: 450px) {
    width: 90%;
  }
}

.faq_btn_container{
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  margin: 0 auto;
  width: 65%;

  @media (max-width: 450px) {
    width: 90%;
  }
}

.faq_text{
  margin-top: 2%;
  margin-left: 2%;
  color: black;
}

.faq_button{
  color: white;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  background-color: #283A97;
  border-radius: 7px;
  width: 150px;
}

.faq_current_page{
  display: flex;
  justify-content: center;
  padding: 0 0 4px 0;
}

.faq_center{
  text-align: center;
}


.dotted__line {
  width: 100%;
  height: 2px;
  border: 2px dotted #000;
}

.block {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}


.page {
  padding: 20px;
  min-height: 520px;
  gap: 10px;
}
