@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.preview__section {
  @include display;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  padding-top: 60px;

  @media (max-width: 1440px) {
    padding: 8vh 0;
  }
  @media (max-width: 750px) {
    padding: 6vh 0;
  }
  @media (max-width: 500px) {
    padding: 4vh 0;
  }
  @media (max-width: 375px) {
    margin-top: 50px;
  }

}

.cart__preview {
  display: flex;
  background-color: white;
  flex-direction: column;
  background-image: url("../../img/previewHome.png");
  background-repeat: no-repeat;
  background-position: right;
  padding: 24px 25px;
  border-radius: 20px;
  gap: 16px;
  margin-top: 2.8rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

  @media (max-width: 450px) {
    background-image: none;
    background-repeat: unset;
    background-position: unset;
    max-width: 350px;
    padding: 24px 15px;
  }

  p {
    width: 65%;
    font-size: 20px;
  }
}

.title {
  font-size: 28px;
  @media (max-width: 450px) {
    font-size: 22px;
  }
}