@import "style/scss/mixins";

.table {
  width: 70%;
  height: 80vh;
  margin: 112px auto;
  border-radius: 20px;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);
  padding: 20px;
  background-color: var(--tabel-users-bg);

  &__close {
    text-align: right;
    margin: 0 10px 15px 0;
    cursor: pointer;
    color: var(--color-text);
  }

  &__bg {
    @include display;
    justify-content: center;
    padding: 30px;
    background-color: #283A9733;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  &__body {
    height: calc(80vh - 150px);
    overflow-y: scroll;
    @include scroll;
  }
}