.message__block {

  .update__message__inf {
    height: 40px;
    background-color: rgb(80,80,80,0.1);
    padding: 10px;
  }

  .send__message__block {
    max-height: 70px;
    border: 1.5px solid #222222;
    display: flex;
  }
  .input__message {
    background-color: rgb(100,100,100,0.2);
    display: flex;
    border: none;
    padding-left: 20px;
    width: 90%;
    color: white;
  }

  input {
    outline: 0 !important;
    caret-color: white;
  }

  button {
    width: 10%;
    height: 46px;
    border: none;
    background-color: rgb(100,100,100,0.2);
    color: white;
    border-bottom-right-radius: 10px;
    min-width: 50px;

    img {
      max-width: 25px;
    }
  }
}