@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.courses {
  display: flex;
  justify-content: center;
  margin: 40px 0;

  &__block {
    @include display;
    gap: 1.3rem;
    &__title {
      font-size: 27px;
    }

    .courses__list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 50px 25px;
      
      @media (max-width: 450px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }
  }
}

.btn {
  padding: 15px 24px ;
}

.course {
  @include display;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.2);
  padding: 20px;
  gap: 10px;
}

.plus {
  padding: 20px 25px;
  background-color: #F8FAFB;
  border-radius: 30px;
}

.input {
  font-size: 17px;
  border: 1px solid $white-text;
  padding: 8px 10px;
  transition: 0.2s;
  min-height: 48px;
  line-height: 18.2px;
  border-radius: 8px;
  display: inline-block;
  width: 100%;
  outline: none;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}


