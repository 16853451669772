@import 'style/scss/app.scss';

.lesson__tasks__block {
  display: flex;
  width: 100%;
}

.lesson__tasks__list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 2%;
  margin: 0 0 20px 15px;
  width: 100%;
}