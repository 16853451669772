@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.slide__additional__information {
  padding: 20px;
  background-color: var(--header-footer-background-color);
  border-radius: 20px;
  min-height: 400px;
  display: flex;
  flex-direction: column;

  p, h1, h2, h3, h4 {
    color: $white-text;
  }

  .arrow__menu {
    display: flex;
    gap: 25px;

    .arrow__block__left, .arrow__block__right {
      border-radius: 100%;
      border: 1px solid #7C7C7C;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .arrow__block__left:hover, .arrow__block__right:hover {
      border: 1px solid white;
    }

    .arrow__block__left {
      rotate: 180deg;
    }
  }

  .slide__information {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .settings__list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 20px;
    }
  }

}
