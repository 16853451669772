@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.admin__new__task {
    height: 50px;
    padding: 10px;
    margin: 15px 0;
    cursor: pointer;
    position: relative;
    background-color: var(--admin-panel-add-block-inputs-background);

    .new__task__name {
        padding: 0 20px;
        height: 100%;
        @include display;
        justify-content: center;
        color: var(--color-text);
        font-weight: 500;
    }
    .admin__new__task__delete {
        width: 20px;
        height: 20px;
        border-radius: 0 0 0 10px;
        background-color: $color-error;
        background-image: url('../../img/close-icon.svg');
        background-size: 12px 12px;
        background-position: center;
        background-repeat: no-repeat;
        right: 0;
        top: 0;
        position: absolute;
        cursor: pointer;
    }
}
