@import 'style/scss/app.scss';

.editor {
    border-radius: 8px;
    Div {
        background-color: var(--compiler-bg);
    }
    Div::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}


