@import '../../style/scss/app.scss';

.add__task__label {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text);
    @media (max-width: 600px) {
      width: 100%;
    }
}

.add__task__select {
    width: 100%;
    height: 30px;
    font-size: 14px;
    border-radius: 5px;
    padding-left: 5px;
    outline: none;
    border: none;
    color: var(--color-text);
    background-color: var(--admin-panel-add-block-inputs-background);
}
