.choice {
  &__text {
    font-size: 20px;
    margin-left: 4px;
    cursor: pointer;
  }

  &__input {
    &_radio {
      margin-right: 10px;
      cursor: pointer;

      &[type="radio"] {
        appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid var(--color-text);
        border-radius: 50%;
        position: relative;
      }

      &[type="radio"]::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--checkbox-bg);
        opacity: 0;
      }

      &[type="radio"]:checked::before {
        opacity: 1;
        background: url("../../img/arrow_choice.svg") no-repeat #fff center center;
      }
    }

    &_checkbox {
      margin-right: 10px;
      cursor: pointer;

      &[type="checkbox"] {
        accent-color: #283A97;
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid #C8CCE1;
        border-radius: 5px;
        position: relative;
      }

      &[type="checkbox"]:checked {
        border: 2px solid #cecece;
        background: url("../../img/arrow_choice.svg") no-repeat #fff center center;
      }
    }
  }
}