@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.add__achievement__block {
  width: 100%;
  max-width: 500px;
  margin: 50px 0;


  &__title {
    color: var(--color-text);
  }

  .add__achievement__form {
    width: 100%;
    margin-top: 15px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 25px 50px;
    gap: 25px;
    background-color: var(--admin-panel-add-block-background);
    

      .add__achievement__button__wrapper {
        width: 100%;
        height: 80px;
        @include display;
        gap: 50px;

        button {
        padding: 10px 20px;
        border: none;
        font-size: 16px;
        cursor: pointer;
        transition: .3s;
        }
      }
  }
}