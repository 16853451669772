@import "style/scss/mixins";

.lessons {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  gap: 10px;
}

.name {
  font-size: 16px;
}

.cyrcle {
  @include display;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  background-color: #283A97;
  cursor: pointer;

  &_white {
    @include display;
    justify-content: center;
    color: #fff;
    width: 30px;
    height: 30px;
    border: 1px solid #283A97;
    border-radius: 50%;
    cursor: pointer;
  }
  &_red {
    @include display;
    justify-content: center;
    background-color: #FFF9F9;
    border: 1px solid #FF3D3D;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
  }

  &_counter {
    @include display;
    gap: 6px;
  }
}

.title {
  border: 1px solid #D4DAEC;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  line-height: 20px;
}
