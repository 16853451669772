@import '../../../style/scss/app.scss';
@import '../../../style/scss/mixins.scss';

.upload__image__input {
    width: 0;
    height: 0;
}

.add__form__title {
    font-weight: 500;
    color: var(--color-text);
}

.upload__image__button__wrapper {
    @include display;
    gap: 10px;

    .upload__image__button {
        height: 50px;
        max-width: 180px;
        @include display;
        justify-content: center;
        cursor: pointer;
        padding: 0 20px;
        gap: 15px;
        border-radius: 5px;
        font-weight: 500;
        color: var(--color-text);
        background-color: var(--admin-panel-add-block-inputs-background);

        .upload__image__icon {
            width: 22px;
            height: 22px;
        }
    }

    .image__name {
        color: var(--color-text);
    }
}