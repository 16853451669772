@import '../../../../style/scss/app.scss';

.sides {
  border: 1px solid $color-text;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  color: $color-text;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }

  .dignity {
    width: 49%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    padding: 50px 20px 50px 50px;
    @media (max-width: 950px) {
      padding: 20px 10px 20px 20px;
      width: 49.5%;

    }
    @media (max-width: 650px) {
      width: 100%;
    }
    h3 {
      font-size: 28px;
      color: #289640;
      @media (max-width: 950px) {
        font-size: 24px;
      }
    }

    p {
      @media (max-width: 950px) {
        font-size: 14px;
      }
    }

    h4 {
      @media (max-width: 950px) {
        font-size: 20px;
      }
      color: #289640;
    }
    .sides__cart__list {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }

  .flaws {
    width: 49%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    padding: 50px 50px 50px 20px;
    @media (max-width: 950px) {
      padding: 20px 20px 20px 10px;
      width: 49.5%;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
    h4 {
      @media (max-width: 950px) {
        font-size: 20px;
      }
      color:$color-error;
    }
    p {
      @media (max-width: 950px) {
        font-size: 14px;
      }
    }

    h3 {
      font-size: 28px;
      color: $color-error;
      @media (max-width: 950px) {
        font-size: 24px;
      }
    }
    .sides__cart__list {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
  }

  hr {

    width: 1px;
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}
