@import 'style/scss/app.scss';

.button {
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: 0.3s;
    color: $white-text;
    background-color: $color-blue;
}

.admin__button:hover {
    background-color: var(--admin-button-hover-background);
}

