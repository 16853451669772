@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.work__section {
  display: flex;
  justify-content: space-between;
  gap: 5%;
  align-items: center;
  @media (max-width: 950px) {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .img__wrapper {
    width: 100%;
    @media (max-width: 950px) {
      width: 100%;
      height: 260px;
      display: flex;
      align-items: center;
      overflow: hidden;
      border-radius: 15px;
    }
    img {
      width: 80%;
      @media (max-width: 950px) {
        width: 100%;
        margin-top: 50px;
      }
    }
  }



  h4 {
    font-size: 26px;
  }

  p {
    font-size: 20px;
  }

  .work__information {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }
}
