@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.message__block {

  .message {
    padding: 10px;
    border-radius: 8px;
    display: flex;
    z-index: 15;
  }

  p {
    color: $color-black;
  }
}

.my__message__block {
  display: flex;
  justify-content: end;

}

.another__message__block {
  display: flex;
  justify-content: start;

}

.my__message {
  background: #D9D9D9;

}

.another__message {
  background: #7C7C7C;

}

.scroll__visible__block {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

}
.setting__message__panel {
  position: absolute;
  z-index: 12;
  width: 150px;
  height: 50px;
  background: rgb(40,40,40);
  border-radius: 10px;
  padding-top: 5px;
  button {
    background: rgb(100,100,100,0.3);
    border: none;
    width: 100%;
    color: white;
  }
}
