@import 'style/scss/mixins';

.table {
  width: 690px;
  height: 80vh;
  margin: 112px auto;
  border-radius: 20px;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);
  padding: 20px;
  background-color: var(--tabel-users-bg);

  @media (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
  }

  &__close {
    text-align: right;
    margin: 0 10px 15px 0;
    cursor: pointer;
    color: var(--color-text);
  }

  &__bg {
    @include display;
    justify-content: center;
    padding: 30px;
    background-color: #283a9733;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    height: 100%;
  }

  &__body {
    height: calc(80vh - 80px);
    overflow-y: scroll; 
    @include scrollTable;
  }
}
