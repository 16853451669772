.modal__settings__chat {
  position: absolute;
  z-index: 20;
  width: 150px;
  height: 50px;
  background: rgb(40,40,40);
  border-radius: 10px;
  padding-top: 5px;
  button {
    background: rgb(100,100,100,0.3);
    border: none;
    width: 100%;
    color: white;
  }
}