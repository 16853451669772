@import 'style/scss/mixins';

.choices__block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-column: 1/3;
}

.label {
  @include display;
  width: 100%;
  gap: 6px;
}


.toggleSwitch {
  position: relative;
  height: 25px;
  width: 50px;
}

.toggleSwitch input {
  opacity: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #B0B0B0;
  transition: 0.15s;
  border-radius: 360px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: #FFFFFF;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #283A97;
}

input:checked + .slider:before {
  transform: translateX(25px);
}

