@import 'style/scss/mixins';

.is_correct {
  @include checkbox;
}

.span {
  @include display;
  gap: 4px;
}

.title {
  border: 1px solid #d4daec;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  line-height: 20px;
  background-color: #fff;
}
