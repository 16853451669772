.user__cart {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #222222;
  border-radius: 6px;
  padding: 12px 10px;

  .time__wrapper {
    display: flex;
    gap: 10px;
  }
  p {
    color: white;
  }
  .inf__block {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  img {
    width: 50px;
    height: 50px;
  }

  .dont__touch {
     pointer-events: none;
  }
}
