@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.rating__page {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $color-text;
  margin: 0 auto;
}

.rating__list {
  width: 100%;
  overflow-x: auto;
  background-color: #fff;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);

  &__content__centering {
    width: 85%;
    height: calc(100% - 100px);
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.this__user__rating {
  width: 100%;
  height: 100px;
  @include display;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid $color-page-childrens;


  .this__user__place {
    width: 10%;
    height: 100%;
    font-size: 30px;
    @include display;
    padding-left: 25px;
  }

  .this__user__place__wrapper {
    width: 10%;
    height: 100%;
    @include display;
    padding-left: 25px;

    .this__user__top__place {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
    }
  }

  .content__centering {
    width: 85%;
    height: 100%;
    display: flex;
  }
}


.dark__theme {
  .this__user__rating {
    background-color: $color-dark-blue;
  }
}

.light__theme {
  .this__user__rating {
    background-color: $color-blue;
  }
}


.user {
  width: 100%;
  margin-top: 50px;
}

.rating__header {
  background-color: #fff;
  display: flex;
  padding: 20px 40px;
  border-radius: 20px 20px 0 0;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);

}

.last {
  width: 10%;
  color: $white-text;
}

.input {
  width: 100%;
  border: none;
  outline: none;
  padding: 20px 40px;
  font-size: 18px;
  border-bottom: 1px solid #c5c5c5;
  background-color: #f3f3f3;
}

.block {
  width: 100%;
  display: grid;
  grid-template-columns:  0.5fr 2fr 1fr 1fr 0.2fr;
}

.header__item {
  width: 100%;

  &_place {
    width: 30%;
  }
}