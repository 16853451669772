@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.button__course {
  padding: 10px 12px;
  border-radius: 10px;
  background-color: #283A97;
  @include display;
  justify-content: center;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: .3s;
  color: #fff;
  z-index: 5;

  @media (max-width: 1360px) {
    padding: 6px 25px;
    font-size: 14px;
  }
  @media (max-width: 720px) {
    padding: 5px 22px;
  }
}

.button__course:hover {
  color: $color-blue;
}

