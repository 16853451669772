@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/app.scss';

.navigate__header {
  height: 100%;
  display: flex;
  gap: 1.1rem;
  font-weight: 500;

  @media (max-width: 450px) {
    display: none;
  }
}

.open__modal {
  margin-left: 12.5px;
  display: flex;
  justify-content: center;

  @media (max-width: 450px) {
    display: none;
  }
}