@import 'style/scss/app';
@import 'style/scss/mixins';


.course__description__wrapper {
  @include display;
  justify-content: center;
  width: 100%;
  padding: 190px 0 60px;
  @media (max-width: 1440px) {
    padding: 100px 0 50px;
  }
  @media (max-width: 900px) {
    padding: 50px 0 50px;

  }

  .course__description__content__wrapper {
    width: calc(100% - 240px);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media (max-width: 1160px) {
      width: calc(100% - 70px);
    }

    @media (max-width: 900px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    .course__description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;
      width: 100%;

      @media (max-width: 900px) {
        margin-top: 50px;
        gap: 35px;
      }

      &__title {
        font-size: 35px;
        color: var(--color-text);
        @media (max-width: 1000px) {
          font-size: 32px;
        }
      }

      .course__description__text {
        width: 86%;
        color: var(--color-text);
        @media (max-width: 1440px) {
          width: 90%;
        }
        @media (max-width: 1000px) {
          width: 92%;
        }

      }

      .btn__next__description {
        width: 100%;
        display: flex;
        gap: 15px;
        @media (max-width: 500px) {
          flex-direction: column;
        }

        Button {
          width: 50%;
          padding: 20px 0;
          font-size: 15px;
          font-weight: 500;
          @media (max-width: 900px) {
            font-size: 14px;
            padding: 15px 0;
            width: 100%;
          }
        }
      }


    }

    .course__preview__image {
      width: 55%;
      height: 100%;

      @media (max-width: 1530px) {
        width: 52%;
      }
      @media (max-width: 1440px) {
        width: 51%;
      }
      @media (max-width: 1000px) {
        width: 50%;
        height: 90%;
      }
      @media (max-width: 900px) {
        width: 100%;
        height: 60%;
      }
      @media (max-width: 770px) {
        width: 100%;
        height: 55%;
      }
    }
  }
}

.course__lessons__wrapper {
  width: 100%;
  min-height: 40vh;
  display: flex;
  justify-content: center;
}
