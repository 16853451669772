

.communication {
  display: flex;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
  h2 {
    font-size: 30px;
    @media (max-width: 950px) {
      font-size: 26px;
    }
    @media (max-width: 650px) {
      font-size: 24px;
    }
  }
  p {
    @media (max-width: 950px) {
      font-size: 14px;
    }
  }
  img {
    @media (max-width: 950px) {
      width: 30%;
      height: 30%;
    }
    @media (max-width: 650px) {
      width: 50%;
      height: 50%;
    }
  }
  .communication__information {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    @media (max-width: 950px) {
      width: 90%;
      gap: 10px;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}
