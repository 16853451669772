@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.add__task__and__taskstring__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
  padding: 20px;
  gap: 20px;
  background-color: var(--admin-panel-add-block-background);
}
.add__task__button {
  width: 75px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  border: var(--admin-panel-confirm-border);
  background-color: var(--admin-panel-add-block-inputs-background);
}

.close__button {
  width: 75px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid $color-red;
  background-color: var(--admin-panel-add-block-inputs-background);
}
