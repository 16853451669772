@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.courses {
  display: flex;
  justify-content: center;
  margin: 40px 0;

  &__block {

    &__title {
      font-size: 27px;
    }

    .courses__list {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 50px 25px;

      @media (max-width: 450px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
      }
    }
  }
}