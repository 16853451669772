@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.new__lesson__block {
  width: 100%;
  .new__lesson {
    width: 100%;
    height: 35px;
    @include display;
    justify-content: space-between;
    padding-left: 15px;
    cursor: pointer;
    transition: 0.3s;
    font-weight: 500;
    border-radius: 5px;
    color: var(--color-text);
    background-color: var(--admin-panel-add-block-inputs-background);
    
    .lesson__arrow {
      margin-right: 15px;
      width: 25px;
      height: 15px;
    }
  
    .new__title__input {
      width: 40%;
      height: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      font-weight: 500;
      padding-left: 10px;
      color: var(--color-text);

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  
    .lesson__tools__wrapper {
      @include display;
      justify-content: space-between;
      gap: 15px;
  
      .lesson__tool__button {
        width: 25px;
        height: 25px;
        background-color: transparent;
        border: none;
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      
      .lesson__delete__button {
        background-image: url('../../img/delete-icon.svg');
      }

      .lesson__edit__button {
        background-image: var(--admin-panel-edit-button-img);
      }
    }
  }
  .new__lesson:hover {
    transform: scale(1.01);
  }

  .new__lesson__add__taskstring {
    width: 50px;
    height: 50px;
    background-image: var(--admin-panel-add-plus-icon);
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 25px;
    transition: 0.1s;
    background-color: var(--admin-panel-add-block-inputs-background);
  }

  .new__lesson__close{
    height: 0;
    margin: 0;
    overflow: hidden;
  }
  
}
.add__lesson__open__arrow .lesson__arrow {
  rotate: 180deg;
}
