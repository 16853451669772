@import '../../../../style/scss/app';
@import '../../../../style/scss/mixins';

.notification {
  display: flex;
  justify-content: start;
  padding: 12px 10px;
  gap: 15px;
  border-bottom: 1px solid #E0E4F8;

  @media (max-width: 850px) {
    gap: 10px;
    padding: 0 10px;
  }
  @media (max-width: 750px) {
    gap: 10px;
    padding: 0 8px;
  }
}

.text__notification {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.title {
  color: #1A1A1A;
  font-weight: 700;
  font-size: 18px;

  @media (max-width: 1240px) {
    font-size: 15px;
  }
}

.description__notification {
  font-size: 16px;
  color: #1A1A1A;
  font-weight: 400;
  @media (max-width: 1240px) {
    font-size: 13px;
  }
}

.go__notification__button {
  color: #283A97;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}

.logo__notification {
  display: flex;
  align-items: center;

}

.not_pic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  @media (max-width: 750px) {
    width: 40px;
    height: 40px;
  }
}