@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/app.scss';

.title__header {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  gap: 12px;

  .menu__platform {
    padding: 10px;
    transition: 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
  .menu__platform:hover {
    transition: 0.1s;
    background-color: rgb(410, 410, 410, 0.2);
  }
}
.logo {
  cursor: pointer;
}