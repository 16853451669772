@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.wrapp {
    display: grid;
    grid-row-gap: 0.3rem;

    label {
        @media (max-width: 1350px) {
            font-size: 13px;
        }
        @media (max-width: 1025px) {
            font-size: 11px;
        }
    }

    input::placeholder {
        @media (max-width: 1025px) {
            font-size: 11px;
        }
    }
}

.icon {
    cursor: pointer;
}

.input {
    justify-content: center;
    border: none;
    background: none;
    width: 70%;
}

.reg__input {
    display: grid;
    grid-template-columns: 1fr 13fr 1fr;
    justify-content: center;
    border: 2px solid #536DF0;
    grid-column-gap: 0.4rem;
    padding: 7px;
    border-radius: 8px;
    grid-row-gap: 0.7rem;

    &_error {
        display: grid;
        grid-template-columns: 1fr 13fr 1fr;
        justify-content: center;
        grid-column-gap: 0.4rem;
        padding: 7px;
        border-radius: 8px;
        grid-row-gap: 0.7rem;
        border: 2px solid crimson;
    }

    @media (max-width: 1025px) {
        border: 1.5px solid #536DF0;
        grid-row-gap: 0.1rem;
        padding: 6px;
    }
    img {
        width: 90%;
        padding: 3px;
    }

    input {
        justify-content: center;
        border: none;
        background: none;
        width: 70%;
    }

    input:focus {
        border: none;
        background: none;
        outline: none;
    }
}