@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';


.rating__list__user__other {
  box-shadow: 0 1px 10px 1px rgba(34, 60, 80, 0.2);
}

.rating__list__user {
  display: grid;
  grid-template-columns:  0.5fr 2fr 1fr 1fr 0.2fr;
  align-items: center;
  width: 100%;
  height: 100px;

  padding: 15px 20px;
  box-shadow: 0 10px 10px 0 #283A9733;
  border-bottom: 1px solid #c5c5c5;
  background-color: $white-text;
  color: #000;

  .rating__user__place {
    width: 10%;
    height: 100%;
    font-size: 30px;
    @include display;
    padding-left: 25px;
    color: #000;
  }

  .top__places {
    width: 10%;
    height: 100%;
    @include display;
    padding-left: 25px;

    .top__places__place {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      width: 40px;
      height: 40px;
    }

    .top__one__place {
      background-image: url('../../img/place_one_icon.png');
    }

    .top__two__place {
      background-image: url('../../img/place_two_icon.png');
    }

    .top__three__place {
      background-image: url('../../img/place_three_icon.png');
    }
  }

  .rating__user {
    width: 75%;
    height: 100%;
    @include display;


  }
}

.rating__user__info {
  flex-direction: column;

  &__exp {
    width: 100%;
    @include display;
    color: #000;
    font-size: 20px;
  }

  &__name {
    width: 100%;
    color: #000;
    font-size: 20px;
  }

  &__rank {
    color: #000;
    width: 100%;
  }

  &__status {
    font-size: 14px;
    color: $white-text;
  }

}

.rating__user__image {
  width: 55px;
  height: 55px;
  border-radius: 180px;
  @include display;
  justify-content: center;

}

.user__info {

  &_text {
    @include display;
    width: 100%;
    gap: 6px;
  }
}

.btn {
  padding: 7px 20px;
  border-radius: 20px;

  &_block {
    width: 10%;
  }
}