@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';


.must__developer {
  display: flex;
  flex-direction: column;
  gap: 25px;

  h2 {
    font-size: 30px;
    @media (max-width: 950px) {
      font-size: 28px;
    }
    @media (max-width: 650px) {
      font-size: 24px;
    }
  }
  .must__information {
    display: flex;
    gap: 10%;
    align-items: center;
    @media (max-width: 950px) {
      flex-direction: column;
    }

    img {
      width: 60%;
      height: 60%;
      @media (max-width: 1500px) {
        width: 30%;
        height: 30%;
      }
      @media (max-width: 950px) {
        width: 40%;
        height: 40%;
      }
    }
  }


  .must__list__cart {
    display: flex;
    flex-wrap: wrap;
    gap: 50px 10%;
  }
}
