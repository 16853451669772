.modal__user {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 64px;
  right: 17%;
  max-width: 300px;
  background-color: #283A97;
  border-radius: 0 0 10px 10px;
  z-index: 1;
  
  @media (max-width: 450px) {
    max-width: unset;
    width: 100%;
    right: 0;
  }

  &__title {
    padding: 20px 12px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 18fr 2fr;
    grid-gap: 1rem;
    cursor: pointer;

    p {
      color: white;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 17px 14px;
    gap: 11px;
    justify-content: start;
    border-top: 1px solid gray;
    cursor: pointer;
  }
}

.modal__user__bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

}

.title__logo {
  width: 45px;
}

.title__vector {
  rotate: -90deg;
}
