.header__chat {
  display: flex;
  justify-content: space-between;
  background-color: rgb(100,100,100,0.2);
  border: 1px solid #222222;
  padding: 5px 20px;
  align-items: center;
  gap: 15px;
  width: 100%;
  position: relative;

  &__interlocutor {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .interlocutor__block {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  h2 {
    font-size: 16px;
    color: white;
  }
  p {
    font-size: 14px;
    color: white;
  }
  img {
    max-width: 35px;
    border-radius: 100%;
  }

  .btn__menu__settings {
    width: 2%;
    display: flex;
    gap: 3px;
    flex-direction: column;
    hr {
      width: 3px;
      height: 3px;
      border-radius: 100%;
      border: none;
      background: white;
    }
  }

  .modal__settings {
    position: absolute;
    width: 100px;
    top: 35px;
    right: 30px;
    padding: 10px;
  }
}