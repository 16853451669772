@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';


.select__chat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  p {
    color: $white-text;
  }
  img{
    max-width: 150px;
  }

}