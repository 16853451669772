@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';

.must__cart {
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 15px;
  @media (max-width: 650px) {
    width: 100%;
  }
  .circle__number {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #2DBD65;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
