@import 'style/scss/mixins';

.lessons {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  gap: 10px;
}

.name {
  font-size: 16px;
}

.cyrcle_counter {
  @include display;
  gap: 6px;
}
