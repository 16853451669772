@import '../../../../../style/scss/mixins.scss';


.info {
  width: 100%;
}
.block {
  @include display;
  justify-content: space-between;
}

.line {
  border: 1px dashed  #7C7C7C;
  width: 100%;
}