@import '../../../../style/scss/app.scss';
@import '../../../../style/scss/mixins.scss';


.cart__note {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 22px;
  background: var(--header-footer-background-color);
  width: 24%;
  border-radius: 10px;
  height: 285px;

  @media (max-width: 1500px) {
    padding: 19px;
    gap: 15px;
  }
  @media (max-width: 1350px) {
    width: 48%;
    gap: 15px;
    height: 200px;
  }
  @media (max-width: 750px) {
    width: 100%;
    height: 150px;
  }
  @media (max-width: 500px) {
    height: 230px;
  }
  p {
    color: $white-text;
    @media (max-width: 1500px) {
      font-size: 14px;
    }
  }
  h4 {
    color: $white-text;
    font-size: 22px;
    @media (max-width: 1500px) {
      font-size: 19px;
    }
  }

}
