@import "style/scss/mixins";

.table {
  &__head {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    background-color: var(--tabel-head-bg);
    padding: 20px 0 20px 20px;
    border-radius: 20px 20px 0 0;

    &_text {
      color: var(--color-text);
      font-weight: 700;
      width: 100%;
    }
  }
}