@import '../../style/scss/app.scss';

.add__form__title {
    color: var(--color-text);
    margin-bottom: 10px;
    font-size: 17px;
    font-weight: 500;
}

.add__form__input {
    width: 85%;
    height: 35px;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    padding: 0 10px;
    color: var(--color-text);
    background-color: var(--admin-panel-add-block-inputs-background);

}

.add__task__input {
    width: 100%;
    height: 30px;
    outline: none;
    border: none;
    border-radius: 5px;
    padding-left: 5px;
    color: var(--color-text);
    background-color: var(--admin-panel-add-block-inputs-background);
}


.add__task__label {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text);
    @media (max-width: 600px) {
      width: 100%;
    }
}

.add__task__long__label {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text);
}

.new__lesson__title__input {
    height: 100%;
    outline: none;
    border: none;
    font-size: 16px;
    background-color: transparent;
    padding-left: 10px;
    font-weight: 500;
    color: var(--color-text);
}


.add__achievement__input {
    width: 300px;
    height: 35px;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0 10px;
    color: var(--color-text);
    background-color: var(--admin-panel-add-block-inputs-background);
    @media (max-width: 600px) {
        width: 100%;
    }
}




.userinfo__label {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    p {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-text);
    }
  
    input {
        width: 100%;
        padding: 17px 10px;
        font-size: 16px;
        outline: none;
        border: none;
        border-radius: 10px;
        transition: .3s;
        color: var(--color-text);
        background-color: var(--profile-userinfo-inputs-background);
    }
    input::placeholder {
        font-size: 16px;
    }
}
