@import "style/scss/app";

input[type=file] {
  display: none;
}

.head {
  background-color: $white-text;
  box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);
  border-radius: 20px;
  padding: 20px;
  width: 100%;


  &_label {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 10px;
    color: #8B8FA4;
    font-size: 12px;
    line-height: 16px;
  }

  &_img {
    width: 48px;
    height: 48px;
  }

  &_btn {
    padding: 12px;
    width: 100%;
    margin-top: 10px;
  }
}

.title {
  border: 1px solid #D4DAEC;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  line-height: 20px;
}