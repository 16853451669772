@import "style/scss/mixins";


.table {
  &__user {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 6px 0 6px 20px;
    border-bottom: 1px solid #dadada;
    background-color: var(--tabel-users-bg);

    &_text {
      color: var(--color-text);
      width: 100%;
    }

    &_avatar {
      width: 50px;
      height: 50px;
      margin-right: 4px;

      &_block {
        @include display;
        width: 100%;
        gap: 6px;
      }
    }
  }

  &__email {
    color: #3c54da;
    width: 100%;
  }
}