@import 'style/scss/mixins';


.options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 20px 20px 60px 20px;
}
.one,
.six {
  grid-column: 1/3;
}

.four {
  font-size: 14px;
}

.five {
  font-size: 14px;
}

.textarea {
  border: 1px solid #d4daec;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  line-height: 20px;
  grid-column: 1/3;
  resize: none;
  min-height: 150px;
  @include scrollTable;
  max-height: 2000px;
}

.title {
  border: 1px solid #d4daec;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  line-height: 20px;
}

.data_block {
  border: 1px solid #d4daec;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}