

.stat__user__course {
  display: flex;
  flex-direction: column;
  hr {
    border: 1px solid rgb(100, 100, 100, 0.3);
  }

  .stat__user__inf {
    display: flex;
    height: 60px;
    align-items: center;

  }
}

.user__inf {
  width: 36.5%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 45px;
  }
}

.task__count {
  width: 30%;
}

.completed__percentage {
  width: 10%;
}

.email {
  width: 21%;
  display: flex;
  justify-content: end;
}