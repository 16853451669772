.modal__platform__cart {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 17px;
  gap: 0.4rem;
  font-weight: 700;
  font-size: 18px;
}

.link__cart__platform {
  text-decoration: none;
}