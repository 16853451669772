@import "style/scss/mixins";

.table {
  &__user {
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    padding: 6px 0 6px 20px;
    border-bottom: 1px solid #dadada;
    background-color: var(--tabel-users-bg);

    &_text {
      color: var(--color-text);
      width: 100%;
      cursor: pointer;
    }

    &_avatar {
      width: 60px;
      height: 60px;
      cursor: pointer;

      &_block {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

  &__link {
    cursor: pointer;
    color: #3c54da;
    width: 100%;
  }
}