@import '../../style/scss/app.scss';
@import '../../style/scss/mixins.scss';

.section__chat {
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 50px;
  overflow-y: hidden;
  display: flex;
  width: 100%;

  .user__list__cart {
    margin-top: 5px;
  }

  .chat__panel {
    width: 100%;
    height: 103vh;
  }



  .message__list {
    max-height: 81vh ;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;

  }

  .message__list::-webkit-scrollbar {
    width: 10px;
    background-color: #222222;
  }

  .message__list::-webkit-scrollbar-thumb {
    background-color: var(--header-footer-background-color);
    border-radius: 10px;
  }
}

.content_section_chat {
  display: flex;
  width: 100%;
  height: 100%;
}
