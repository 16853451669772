@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';

.statistics__course {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  min-height: 100vh;
  .logo__block {
    padding: 25px 25px 0 25px ;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .btn__back {
      cursor: pointer;
    }

    .information__course {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 50px;
      }
    }
  }

  .table__block {
    width: 90%;
    border: 1px solid rgb(100, 100, 100, 0.4);
    min-height: 10vh;
    border-radius: 20px;

    .header__table {
      height: 45px;
      background-color: rgb(100, 100, 100, 0.3);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 0 30px;
    }

    .user__stat__list {
      display: flex;
      flex-direction: column;
      max-height: 50vh;
      min-height: 50vh;
      overflow-y: scroll;
      @include scroll;



    }

  }

  .footer__menu {
    width: 100%;
    padding-left: 20px;
    padding-bottom: 40px;
    height: 100%;
    justify-content: end;
    margin-top: 100px;
  }
}