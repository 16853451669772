@import '../../style/scss/app.scss';

.add__form__title {
    color: var(--color-text);
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 17px;
}
.add__form__textarea {
    width: 85%;
    height: 200px;
    resize: none;
    border: none;
    border-radius: 5px;
    outline: none;
    padding: 10px;
    color: var(--color-text);
    background-color: var(--admin-panel-add-block-inputs-background);
}


.add__task__label {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text);
    @media (max-width: 600px) {
      width: 100%;
    }
}

.add__task__textarea {
    width: 100%;
    height: 100px;
    resize: none;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 5px;
    color: var(--color-text);
    background-color: var(--admin-panel-add-block-inputs-background);
}

.add__task__theory__textarea {
    width: 100%;
    height: 350px;
    padding: 5px;
    outline: none;
    border: none;
    resize: none;
    border-radius: 5px;
    color: var(--color-text);
    background-color: var(--admin-panel-add-block-inputs-background);
}
