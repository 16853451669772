.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 91%;
  min-height: 91%;
  .message__list {
    min-height: 85%;
    max-height: 85%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  hr {
    border: none;
  }
}