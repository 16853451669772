@import 'style/scss/app.scss';
@import 'style/scss/mixins.scss';


.user__profile {
  @media (max-width: 1140px) {
    flex-direction: column;
  }

  &__wrapper {
    width: 100%;
    @include display;
    justify-content: center;
    flex-direction: column;
  }


  &__content__wrapper {
    width: calc(100% - 240px);
    position: relative;
    background-color: $white-text;
    margin-top: 50px;
    padding: 0 40px;
    border-radius: 20px;
    box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);

    @media (max-width: 450px) {
      padding: 0 20px;
    }
    @media (max-width: 1140px) {
      width: calc(100% - 70px);
    }
  }
}


.userinfo__form {
  @include display;
  gap: 25px;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  height: 100%;

  @media (max-width: 1140px) {
    width: 100%;
  }
}

.not__courses {
  text-align: center;
  font-size: 36px;
  color: #a6a3a3;

  @media (max-width: 950px) {
    margin-left: 0;
    font-size: 34px;
    margin-top: 50px;
  }
  @media (max-width: 400px) {
    margin-left: 0;
    font-size: 30px;
    margin-top: 50px;
  }
}

.link {
  text-decoration: none;
  color: #283A97;
  font-weight: 500;
}

.btn {
  background-color: #FFF9F9;
  color: #FF3D3D;
  padding: 15px 24px;
  border: 1px solid #FF3D3D;
}

.avatar {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.info__block {
  @include display;

  &_text {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.user {

  &__started__courses__list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media (max-width: 1650px) {
      gap: 30px;
    }
    @media (max-width: 1280px) {
      gap: 20px;
    }
    @media (max-width: 950px) {
      gap: 20px;
    }
    @media (max-width: 730px) {
      gap: 10px;
    }
    @media (max-width: 700px) {
      gap: 20px;
      justify-content: center;
    }
    @media (max-width: 450px) {

    }
  }

  &__courses_block {
    width: 100%;
  }

  &__achievements {
    &__wrapper {
      gap: 10px;
      background-color: $white-text;
      box-shadow: 6px 4px 21px 0 rgba(34, 60, 80, 0.2);
      margin: 50px 0;
      width: calc(100% - 240px);
      padding: 20px 40px;
      border-radius: 20px;

      @media (max-width: 450px) {
        width: 85%;
        padding: 20px;
      }
    }

    &_none {
      color: #a6a3a3;
      text-align: center;
      width: 100%;
    }

    &__list {
      max-height: 450px;
      border-radius: 15px;
      padding: 10px 4px ;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      overflow: hidden;
      overflow-y: scroll;
      width: 100%;

      @media (max-width: 1440px) {
        gap: 15px;
      }

      li {
        width: calc((100% - 20px) / 2);

        @media (max-width: 1440px) {
          width: calc((100% - 15px) / 2);
        }
      }
    }
  }


  &__avatar_wrapper {
    @include display;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
  }


  &__profile {
    min-height: 400px;
    width: 100%;
    margin: 70px 0;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    @media (max-width: 950px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin: 45px 0;
      min-height: 700px;
    }
  }

  &__achievements__list::-webkit-scrollbar {
    width: 10px;
  }

  &__achievements__list::-webkit-scrollbar-thumb {
    border-radius: 15px;
  }

  &__courses__block {
    margin: 75px 0;
    display: flex;

    @media (max-width: 950px) {
      flex-direction: column;
      align-items: center;
    }
  }


  &__avatar {
    width: 80px;
    height: 80px;
    min-height: 80px;
    min-width: 80px;
    border-radius: 50%;
  }

  &__rank, &__exp {
    font-size: 16px;
    font-weight: 600;
  }

  &__exp {
    color: #283A97;
  }

  &__rank {
    color: var(--profile-user-rank-color);
  }
}

.title {
  margin: 0 0 10px 4px;
}

.input__checkbox {
  @include checkbox;
}

.label {
  @include display;
  gap: 4px;
}