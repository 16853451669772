html {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #EDEDF3;
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
}


main.light__body {
  --checkbox-bg: #4056ce ;
  --invert-text: invert(0);
  --compiler-bg: #ffffff;
  --line-main-background-color-change: #4056ce;
  --button-change-them-color: #4056ce;
  --header-footer-background-color: #283A97;
  --header-title-color: #fff;
  --theme-changing-light-button-background: #fff;
  --theme-changing-light-button-text-color: #fff;
  --theme-changing-dark-button-background: #C8CCE1;
  --theme-changing-dark-button-text-color: #292929;
  --button-hover-background: #4254AE;
  --color-text: #292929;
  --color-text-rating: #292929;
  --courses-list-title: #283A97;
  --course-background-color: #fff;
  --course-edit-button-background: #e3e3e3;
  --profile-user-rank-color: #4254AE;
  --profile-userinfo-inputs-background: #C8CCE1;
  --started-course-background: #fff;
  --started-course-hover-color: #536DF0;
  --started-course-progress-background: #D9D9D9;
  --started-course-progress-line: #283A97;
  --achievement-background: #fff;
  --admin-panel-edit-button-img: url('../../style/img/edit-icon-light.svg');
  --admin-panel-filter-button-background: #C8CCE1;
  --admin-panel-filter-button-active-background: #283A97;
  --admin-panel-filter-button-active-color: #fff;
  --admin-panel-search-background: #FFFFFF;
  --admin-panel-search-button-icon: url('../../style/img/search-icon-light.svg');
  --admin-button-hover-background: #273886;
  --admin-panel-user-card-background: #FFFFFF;
  --admin-panel-add-block-background: #FFFFFF;
  --admin-panel-add-block-inputs-background: #EDEDF3;
  --admin-panel-confirm-image: url('../../style/img/confirm-icon-blue.svg');
  --admin-panel-confirm-border: 2px solid #283A97;
  --admin-panel-add-plus-icon: url('../../style/img/add-lesson-icon-light.svg');
  --admin-panel-task-string-scroll-background: #283a97;
  --color-shadow: #7C7C7C;
  --compiler-hr: #4056ce;
  --scroll-bar-trak-bg: #dadada;
  --scroll-bar-trak-thumb: rgb(201, 201, 201);
  --tabel-users-bg: #fff;
  --tabel-head-bg: #dcdcdc;
}

main.dark__body {
  --tabel-head-bg: #3d3d3d;
  --tabel-users-bg: #252525;
  --checkbox-bg: #fff ;
  --scroll-bar-trak-bg: #181818;
  --scroll-bar-trak-thumb: #252525;
  --invert-text: invert(1);
  --compiler-bg: #292929;
  --compiler-hr: #292929;
  --line-main-background-color-change: #7a7a7a;
  --button-change-them-color: #fff;
  --color-shadow: none;
  --must-background-color: #100D20;
  --header-footer-background-color: #2e2e36;
  --header-title-color: #fff;
  --theme-changing-light-button-background: #C8CCE1;
  --theme-changing-light-button-text-color: #292929;
  --theme-changing-dark-button-background: #181B29;
  --theme-changing-dark-button-text-color: #fff;
  --button-hover-background: #10093c;
  --color-text: #fff;
  --courses-list-title: #fff;
  --course-background-color: #2b2b2f;
  --course-edit-button-background: #23232c;
  --profile-user-rank-color: #fff;
  --profile-userinfo-inputs-background: #2e2e36;
  --started-course-background: #2e2e36;
  --started-course-hover-color: #fff;
  --started-course-progress-background: #fff;
  --started-course-progress-line: #2DBD65;
  --achievement-background: #2e2e36;
  --admin-panel-edit-button-img: url('../../style/img/edit-icon.svg');
  --admin-panel-filter-button-background: #181B29;
  --admin-panel-filter-button-active-background: #EDEDF3;
  --admin-panel-filter-button-active-color: #292929;
  --admin-panel-search-background: #181B29;
  --admin-panel-search-button-icon: url('../../style/img/search-icon-dark.svg');
  --admin-button-hover-background: #10093c;
  --admin-panel-user-card-background: #181B29;
  --admin-panel-add-block-background: #181B29;
  --admin-panel-add-block-inputs-background: #0f1119;
  --admin-panel-confirm-image: url('../../style/img/confirm-icon-green.svg');
  --admin-panel-confirm-border: 2px solid green;
  --admin-panel-add-plus-icon: url('../../style/img/add-lesson-icon-dark.svg');
  --admin-panel-task-string-scroll-background: #0f1119;
}

$compiler-background-color: #181B29;
$compiler-console-background-color: #181B29;
$compiler-hr-color: #292929;

$color-text: var(--color-text);
$color-exp: #9747ff;
$white-text: #fff;
$home-title-color: #536DF0;
$color-light-blue: #536df0;
$color-light-blue-hover: #4254ae;
$theme-changing-background-color: #C8CCE1;
$left-line-square-background: #283A97;
$left-line-color: #7C7C7C;
$color-task-done: #289640;
$color-task-notdone: #D9D9D9;
$color-task-close: #BB0000;
$color-easy-complexity: #2DBD65;
$color-medium-complexity: #FFE052;
$color-hard-complexity: #FA6D41;
$color-error: #BB0000;


$color-gray: #bfbfbf;
$color-black: #292929;
$color-light-black: #393939;
$color-white: white;
$color-page-childrens: #1a2551;
$color-light-purple-border: #8F9AD2;
$color-dark-gray: #7C7C7C;
$color-dark-blue: #181b29;
$color-input: #0d1f3a;
$color-blue: #283a97;
$color-blue-hover: #5361ac;
$color-purple-dark: #201a41;
$color-button-hover: #10093c;
$color-site-background: #0f1119;
$color-light-site-background: #EDEDF3;
$color-light-purple: #6C36FE;
$color-light-gray: #d4daec;
$color-gray-hover: #c8cce1;
$color-red: #B03333;

$color-top-gold: #ffe052;
$color-top-silver: #e5dcdc;
$color-top-bronze: #ac4810;

.dark__body {
  background-color: $color-site-background;
}

.light__body {
  background-color: $color-light-site-background;
}


div.cm-s-material.CodeMirror {
  height: auto;
  min-height: 300px;
  background-color: var(--compiler-background-color);
}

label,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
div {
  button, div {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: var(--color-text);
  }
  button {
    transition: 0.3s;
    cursor: pointer;
  }
  .container {
    width: 65%;
    height: 100%;
   
    @media (max-width: 1160px) {
      width: calc(100% - 70px);
    }
  }
    ul, li {
      list-style: none;
    }
    iframe {
      display: none;
    }
}

